/**
 * @typedef {'fr' | 'en' | 'es' | 'pt' | 'it' | 'de' | (string & {})} Lang
 */

/**
 * @typedef {'FR' | 'ES'} Country
 */

/**
 * @template T
 * @param {T} source
 * @returns {T}
 */
export function recursiveMerge(source, ...args) {
  let result = {}

  args.unshift(source)

  for (const arg of args) {
    if (arg) {
      for (const key in arg) {
        const value = arg[key]
        if (key) {
          if (value && typeof value === 'object') {
            result[key] = recursiveMerge(result[key], value)
          } else {
            result[key] = value
          }
        }
      }
    }
  }

  return /** @type {T} */ (result)
}

/**
 * @param {string | null} [libraryPath='']
 * @param {HTMLImageElement | import('canvas').Image | null} [strainerImage]
 */
export function loadAssets(libraryPath, strainerImage) {
  if (!libraryPath) libraryPath = ''
  const fonts = [
    new FontFace('IBMPlexSans', 'url(' + libraryPath + 'fonts/IBMPlexSans-Regular.otf)', { weight: '400' }),
    new FontFace('IBMPlexSans-bold', 'url(' + libraryPath + 'fonts/IBMPlexSans-Bold.otf)', { weight: '700' }),
    new FontFace('IBMPlexSansCondensed', 'url(' + libraryPath + 'fonts/IBMPlexSansCondensed-Regular.otf)', { weight: '400' }),
    new FontFace('IBMPlexSansCondensed-bold', 'url(' + libraryPath + 'fonts/IBMPlexSansCondensed-Bold.otf)', { weight: '700' }),
  ]

  /** @type Promise[] */
  let promises = fonts.map((font) => font.load().then((font) => document.fonts.add(font)));
  let strainerImagePromise

  if (!strainerImage) {
    strainerImagePromise = loadImage('passoire-energetique.png')
    promises.push(strainerImagePromise);
  }

  return Promise.allSettled(promises).then(() => strainerImagePromise);

  function loadImage(src) {
    const img = new Image();
    img.src = libraryPath + src;
    return img.decode().then(() => img);
  }
}