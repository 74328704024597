import { CanvasDrawer, Font, textFromModel } from "./CanvasDrawer.js";

/** @type {{[key: string]: import('./CanvasDrawer.js').Style}} */
const styles = {
  background: { fill: '#eee' },
  textGray: { fill: '#696969' },
  columnBackground: { fill: '#fff' },
  column: { fill: '#fff' },
}

/** @type {{[key: string]: import('./CanvasDrawer.js').TextModel}} */
const textModels = {
  energyRatingScale: { style: { fill: '#000' }, font: new Font('IBMPlexSansCondensed-bold', 14, '700') },
  columnHeader: { style: styles.textGray, font: new Font('IBMPlexSansCondensed', 12, '400') },
  columnText: { style: styles.column, font: new Font('IBMPlexSansCondensed', 15, '400') },
}

/**
 * @type {{[k in Lang]:{
 *  energyRatingScale: import('./CanvasDrawer.js').Text;
 *  consumption: import('./CanvasDrawer.js').Text;
 *  consumptionUnit: import('./CanvasDrawer.js').Text;
 *  emissions: import('./CanvasDrawer.js').Text;
 *  emissionsUnit: import('./CanvasDrawer.js').Text;
 *  letterAText: import('./CanvasDrawer.js').Text;
 *  letterGText: import('./CanvasDrawer.js').Text;
 * }}}
 */
const texts = {
  fr: {
    energyRatingScale: textFromModel(textModels.energyRatingScale, 'ÉCHELLE DE LA PERFORMANCE ÉNERGÉTIQUE'),
    consumption: textFromModel(textModels.columnHeader, 'Consommation d\'énergie'),
    consumptionUnit: textFromModel(textModels.columnHeader, 'kWh/m² an'),
    emissions: textFromModel(textModels.columnHeader, 'Émissions'),
    emissionsUnit: textFromModel(textModels.columnHeader, 'kgCO₂/m² an'),
    letterAText: textFromModel(textModels.columnText, 'plus efficace'),
    letterGText: textFromModel(textModels.columnText, 'moins efficace'),
  },
  en: {
    energyRatingScale: textFromModel(textModels.energyRatingScale, 'ENERGY RATING SCALE'),
    consumption: textFromModel(textModels.columnHeader, 'Energy consumption'),
    consumptionUnit: textFromModel(textModels.columnHeader, 'kWh/m² year'),
    emissions: textFromModel(textModels.columnHeader, 'Emissions'),
    emissionsUnit: textFromModel(textModels.columnHeader, 'kgCO₂/m² year'),
    letterAText: textFromModel(textModels.columnText, 'most efficient'),
    letterGText: textFromModel(textModels.columnText, 'least efficient'),
  },
  es: {
    energyRatingScale: textFromModel(textModels.energyRatingScale, 'ESCALA DE LA CALIFICACIÓN ENERGÉTICA'),
    consumption: textFromModel(textModels.columnHeader, 'Consumo de energìa'),
    consumptionUnit: textFromModel(textModels.columnHeader, 'kWh/m² año'),
    emissions: textFromModel(textModels.columnHeader, 'Emisiones'),
    emissionsUnit: textFromModel(textModels.columnHeader, 'kgCO₂/m² año'),
    letterAText: textFromModel(textModels.columnText, 'más eficientes'),
    letterGText: textFromModel(textModels.columnText, 'menos eficientes'),
  },
  pt: {
    energyRatingScale: textFromModel(textModels.energyRatingScale, 'ESCALA DE CLASSIFICAÇÃO ENERGÉTICA'),
    consumption: textFromModel(textModels.columnHeader, 'Consumo de energia'),
    consumptionUnit: textFromModel(textModels.columnHeader, 'kWh/m² ano'),
    emissions: textFromModel(textModels.columnHeader, 'Emissões'),
    emissionsUnit: textFromModel(textModels.columnHeader, 'kgCO₂/m² ano'),
    letterAText: textFromModel(textModels.columnText, 'mais eficiente'),
    letterGText: textFromModel(textModels.columnText, 'menos eficiente'),
  },
  it: {
    energyRatingScale: textFromModel(textModels.energyRatingScale, 'SCALA DI CLASSIFICAZIONE ENERGETICA'),
    consumption: textFromModel(textModels.columnHeader, 'Consumo energetico'),
    consumptionUnit: textFromModel(textModels.columnHeader, 'kWh/m² anno'),
    emissions: textFromModel(textModels.columnHeader, 'Emissioni'),
    emissionsUnit: textFromModel(textModels.columnHeader, 'kgCO₂/m² anno'),
    letterAText: textFromModel(textModels.columnText, 'più efficiente'),
    letterGText: textFromModel(textModels.columnText, 'meno efficiente'),
  },
  de: {
    energyRatingScale: textFromModel(textModels.energyRatingScale, 'ENERGIEEFFIZIENZSKALA'),
    consumption: textFromModel(textModels.columnHeader, 'Energieverbrauch'),
    consumptionUnit: textFromModel(textModels.columnHeader, 'kWh/m² Jahr'),
    emissions: textFromModel(textModels.columnHeader, 'Emissionen'),
    emissionsUnit: textFromModel(textModels.columnHeader, 'kgCO₂/m² Jahr'),
    letterAText: textFromModel(textModels.columnText, 'effizienter'),
    letterGText: textFromModel(textModels.columnText, 'weniger effizient'),
  }
}

const letters = [
  { name: 'A', color: '#0D964E' },
  { name: 'B', color: '#21B24B' },
  { name: 'C', color: '#99CA3B' },
  { name: 'D', color: '#99CA3B' },
  { name: 'E', color: '#ECE824' },
  { name: 'F', color: '#F1B519' },
  { name: 'G', color: '#E52E2A' },
]

/**
 * @param {{
 *  cnvs: HTMLCanvasElement | import('canvas').Canvas;
 *  fixedWidth?: number | null;
 *  fixedHeight?: number | null;
 *  lang?: import("./src/helpers.js").Lang | null;
 *  consumptionClass?: string | null;
 *  consumption?: number | string | null;
 *  emissionsClass?: string | null;
 *  emissions?: number | string | null;
 * }} config
 */
export default function drawSpanishDiagnosticLabel({ cnvs, fixedWidth, fixedHeight, lang, consumptionClass, consumption, emissionsClass, emissions }) {
  if (!lang) lang = 'es'

  const width = 570;
  const height = 430;

  const rowsTop = 45
  const rowsInlineMargin = 10
  const columnHeight = 45
  const gap = 10
  const rowsWidth = width - rowsInlineMargin * 2 - gap * 2

  const rowsWidths = [
    0.61 * rowsWidth,
    0.195 * rowsWidth,
    0.195 * rowsWidth,
  ]

  const valueCardArrowWidth = 15
  const valueCardPadding = 5

  const d = new CanvasDrawer(cnvs)

  d.autosize(width, height, fixedWidth, fixedHeight)

  d.drawRoundedRectangle(0, 0, width, height, rowsInlineMargin, styles.background)

  for (let i = 0; i < letters.length; i++) {
    const letter = letters[i];
    const top = rowsTop + ((gap + columnHeight) * i)
    const arrowWidth = 25
    const width = 120 + (arrowWidth * i)

    for (let j = 0; j < rowsWidths.length; j++) {
      let left = rowsInlineMargin
      for (let k = 0; k < j && j > 0; k++)  left += rowsWidths[k] + gap

      d.drawRoundedRectangle(left, top, rowsWidths[j], columnHeight, 4, styles.columnBackground)
    }

    d.drawRoundedLetterRow(rowsInlineMargin, top, width, columnHeight, arrowWidth, { fill: letter.color, stroke: letter.color, strokeWidth: 0 }, 5)
    const letterMetrix = d.drawText(rowsInlineMargin + 5, top + columnHeight - 10, { txt: letter.name, style: { fill: '#fff' }, font: new Font('IBMPlexSans-bold', 35, '700') }, undefined, undefined, undefined, true)

    if (typeof texts[lang]['letter' + letter.name + 'Text'] === 'object' && letterMetrix) {
      d.drawText(rowsInlineMargin + 5 + letterMetrix.width + 2, top + columnHeight - 10, texts[lang]['letter' + letter.name + 'Text'])
    }

    if (consumption && consumptionClass && letter.name === consumptionClass.toUpperCase()) {
      const valueCardX = rowsInlineMargin + rowsWidths[0] + gap
      d.drawRoundedLetterRow(valueCardX, top, rowsWidths[1] - valueCardArrowWidth, columnHeight, valueCardArrowWidth, { fill: '#000' }, 5, 'left')
      d.drawText(valueCardX + rowsWidths[1] - valueCardPadding, top + columnHeight * 0.55, { txt: consumption.toString(), style: { fill: '#fff' }, font: new Font('IBMPlexSans-bold', 30, '700') }, undefined, 'right', 'middle')
    }

    if (emissions && emissionsClass && letter.name === emissionsClass.toUpperCase()) {
      const valueCardX = rowsInlineMargin + rowsWidths[0] + gap + rowsWidths[1] + gap
      d.drawRoundedLetterRow(valueCardX, top, rowsWidths[2] - valueCardArrowWidth, columnHeight, valueCardArrowWidth, { fill: '#000' }, 5, 'left')
      d.drawText(valueCardX + rowsWidths[1] - valueCardPadding, top + columnHeight * 0.55, { txt: emissions.toString(), style: { fill: '#fff' }, font: new Font('IBMPlexSans-bold', 30, '700') }, undefined, 'right', 'middle')
    }
  }

  d.drawText(15, 24, texts[lang].energyRatingScale)

  d.drawText(rowsInlineMargin + rowsWidths[0] + gap + rowsWidths[1] * 0.5, 24, texts[lang].consumption, undefined, 'center')
  d.drawText(rowsInlineMargin + rowsWidths[0] + gap + rowsWidths[1] * 0.5, 24 + texts[lang].consumption.font.lineHeight, texts[lang].consumptionUnit, undefined, 'center')

  d.drawText(rowsInlineMargin + rowsWidths[0] + gap + rowsWidths[1] + gap + rowsWidths[2] * 0.5, 24, texts[lang].emissions, undefined, 'center')
  d.drawText(rowsInlineMargin + rowsWidths[0] + gap + rowsWidths[1] + gap + rowsWidths[2] * 0.5, 24 + texts[lang].emissions.font.lineHeight, texts[lang].consumptionUnit, undefined, 'center')
}