/**
 * @typedef Text
 * @property {string | number} txt
 * @property {Style} style
 * @property {Font} font
 * 
 * @typedef {Omit<Text, 'txt'>} TextModel
 */

/**
 * @typedef Style
 * @property {string | null} [fill] background color
 * @property {string | null} [stroke] border color
 * @property {number | null} [strokeWidth] thickness of the border
 */

const defaultLineHeightPercent = 120

export class CanvasDrawer {
    /** @type {HTMLCanvasElement | import('canvas').Canvas} */
    cnvs
    /** @type {CanvasRenderingContext2D} */
    ctx
    /** @type {string} */
    libraryPath

    /**
     * @param {HTMLCanvasElement | import('canvas').Canvas} cnvs
     * @param {string} [libraryPath]
     */
    constructor(cnvs, libraryPath) {
        this.cnvs = cnvs
        const ctx = /** @type { CanvasRenderingContext2D | null } */ (cnvs.getContext('2d'))

        if (!ctx) throw new Error("can't get CanvasRenderingContext2D from the canvas")

        this.ctx = ctx
        if (libraryPath) this.libraryPath = libraryPath
    }

    /**
     * @param {number} cnvsWidth
     * @param {number} cnvsHeight
     * @param {number | null} [fixedWidth]
     * @param {number | null} [fixedHeight]
     */
    autosize(cnvsWidth, cnvsHeight, fixedWidth, fixedHeight) {
        let scale = 1
        /** @type {number} */
        let scaleX
        /** @type {number} */
        let scaleY

        if (fixedWidth && fixedHeight) {
            scaleX = fixedWidth / cnvsWidth
            scaleY = fixedHeight / cnvsHeight
            scale = Math.min(scaleX, scaleY)
            this.cnvs.width = fixedWidth
            this.cnvs.height = fixedHeight
        } else if (fixedWidth) {
            scale = fixedWidth / cnvsWidth
            this.cnvs.width = fixedWidth
            this.cnvs.height = cnvsHeight * scale
        } else if (fixedHeight) {
            scale = fixedHeight / cnvsHeight
            this.cnvs.width = cnvsWidth * scale
            this.cnvs.height = fixedHeight
        } else if (this.cnvs.width && this.cnvs.height) {
            scaleX = this.cnvs.width / cnvsWidth
            scaleY = this.cnvs.height / cnvsHeight
            scale = Math.min(scaleX, scaleY)
        } else {
            this.cnvs.width = cnvsWidth
            this.cnvs.height = cnvsHeight
        }

        this.ctx.scale(scale, scale)
    }

    /**
     * @param {Style} style
     * @param {[x: number, y: number][]} points 
     */
    drawPolyline(style, points) {
        const firstPoint = points[0]
        this.ctx.beginPath()
        this.ctx.moveTo(firstPoint[0], firstPoint[1])
        points.shift()
        points.forEach((point) => { this.ctx.lineTo(point[0], point[1]) })

        if (style.stroke) {
            this.ctx.lineWidth = style.strokeWidth ?? 0
            this.ctx.strokeStyle = style.stroke
            this.ctx.stroke()
        }
    }

    /**
     * @param {[x: number, y: number]} point1
     * @param {[x: number, y: number]} point2
     * @param {Style} style
     */
    drawLine(point1, point2, style) {
        this.drawPolyline(style, [point1, point2])
    }

    drawPolygon(style, points) {
        this.drawPolyline({}, points)
        this.ctx.closePath()
        if (style.fill) {
            this.ctx.fillStyle = style.fill
            this.ctx.fill()
        }
        if (style.stroke) {
            this.ctx.lineWidth = style.strokeWidth
            this.ctx.strokeStyle = style.stroke
            this.ctx.stroke()
        }
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} radius
     * @param {Style} style
     */
    drawCircle(x, y, radius, style) {
        this.ctx.beginPath()
        this.ctx.arc(x, y, radius, 0, Math.PI * 2, true)
        if (style.fill) {
            this.ctx.fillStyle = style.fill
            this.ctx.fill()
        }
        if (style.stroke) {
            this.ctx.lineWidth = style.strokeWidth ?? 0
            this.ctx.strokeStyle = style.stroke
            this.ctx.stroke()
        }
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {Text} text
     * @param {boolean | null} [outerStroke]
     * @param {CanvasTextAlign | null} [align]
     * @param {CanvasTextBaseline | null} [baseline]
     * @param {boolean | null} [metrix]
     * @returns {TextMetrics | null}
     */
    drawText(x, y, text, outerStroke, align, baseline, metrix) {
        this.ctx.font = text.font.toString()
        this.ctx.fillStyle = text.style.fill ?? '#000'
        this.ctx.strokeStyle = text.style.stroke ?? '#000'
        this.ctx.textAlign = align ?? 'left'
        this.ctx.textBaseline = baseline ?? 'alphabetic'
        if (text.style.stroke && text.style.strokeWidth) this.ctx.lineWidth = outerStroke ? text.style.strokeWidth * 2 : text.style.strokeWidth
        this.ctx.fillText(text.txt.toString(), x, y)
        if (text.style.stroke && text.style.strokeWidth) {
            if (outerStroke) {
                this.ctx.strokeText(text.txt.toString(), x, y)
                this.ctx.save()
                this.ctx.globalCompositeOperation = 'destination-out'
                this.ctx.globalAlpha = 1
                this.ctx.fillStyle = "#000"
                this.ctx.fillText(text.txt.toString(), x, y)
                this.ctx.restore()
                this.ctx.save()
                this.ctx.globalCompositeOperation = 'destination-over'
                this.ctx.fillText(text.txt.toString(), x, y)
                this.ctx.restore()
            } else {
                this.ctx.save()
                this.ctx.globalCompositeOperation = 'destination-out'
                this.ctx.globalAlpha = 1
                this.ctx.strokeStyle = "#000"
                this.ctx.strokeText(text.txt.toString(), x, y)
                this.ctx.restore()
                this.ctx.strokeText(text.txt.toString(), x, y)
            }
        }
        if (metrix) return this.ctx.measureText(text.txt.toString())
        return null
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} radius
     * @param {Style} style
     */
    drawRoundedRectangle(x, y, width, height, radius, style) {
        if (width < 2 * radius) radius = width / 2
        if (height < 2 * radius) radius = height / 2
        this.ctx.beginPath()
        this.ctx.moveTo(x + radius, y)
        this.ctx.arcTo(x + width, y, x + width, y + height, radius)
        this.ctx.arcTo(x + width, y + height, x, y + height, radius)
        this.ctx.arcTo(x, y + height, x, y, radius)
        this.ctx.arcTo(x, y, x + width, y, radius)
        this.ctx.closePath()
        if (style.fill) {
            this.ctx.fillStyle = style.fill
            this.ctx.fill()
        }
        if (style.stroke) {
            this.ctx.lineWidth = style.strokeWidth ?? 0
            this.ctx.strokeStyle = style.stroke
            this.ctx.stroke()
        }
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {number} arrow
     * @param {Style} style
     * @param {number} roundWidth
     * @param {'right' | 'left'} [direction='right']
     */
    drawRoundedLetterRow(x, y, width, height, arrow, style, roundWidth, direction) {
        if (!direction) direction = 'right'
        x = x + (style.strokeWidth ?? 0) / 2
        y = y + (style.strokeWidth ?? 0) / 2
        width = width - (style.strokeWidth ?? 0) / 2
        height = height - (style.strokeWidth ?? 0)
        const points =
            direction === 'right'
                ? [
                    [x, y],
                    [x + width, y],
                    [x + width + arrow, y + height / 2],
                    [x + width, y + height],
                    [x, y + height]
                ]
                : [
                    [x + arrow + width, y],
                    [x + arrow, y],
                    [x, y + height / 2],
                    [x + arrow, y + height],
                    [x + arrow + width, y + height]
                ]
        const firstPoint = points[0]
        const lastPoint = points[points.length - 1]

        points.shift()
        points.pop()

        this.ctx.beginPath()
        this.ctx.moveTo(firstPoint[0], firstPoint[1] + roundWidth)
        if (roundWidth != 0) this.ctx.quadraticCurveTo(firstPoint[0], firstPoint[1], firstPoint[0] + (roundWidth * (direction === 'right' ? 1 : -1)), firstPoint[1])

        points.forEach((point) => { this.ctx.lineTo(point[0], point[1]) })

        this.ctx.lineTo(lastPoint[0] + (roundWidth * (direction === 'right' ? 1 : -1)), lastPoint[1])
        if (roundWidth != 0) {
            this.ctx.quadraticCurveTo(lastPoint[0], lastPoint[1], lastPoint[0], lastPoint[1] - roundWidth)
            this.ctx.closePath()
        }

        if (style.fill) {
            this.ctx.fillStyle = style.fill
            this.ctx.fill()

        }

        if (style.stroke) {
            this.ctx.lineWidth = style.strokeWidth ?? 0
            this.ctx.strokeStyle = style.stroke
            this.ctx.stroke()
        }
    }

    /**
     * @param {*} x
     * @param {*} y
     * @param {*} width
     * @param {*} height
     * @param {*} arrow
     * @param {*} fill
     */
    drawLetterRow(x, y, width, height, arrow, fill) {
        this.drawPolygon({ fill }, [
            [x, y],
            [x + width, y],
            [x + width + arrow, y + height / 2],
            [x + width, y + height],
            [x, y + height]
        ])
    }

    /**
     * 
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {Style} style
     * @param {boolean | null} [innerStroke]
     */
    drawGesLetterRow(x, y, width, height, style, innerStroke) {
        if (innerStroke && style.stroke && style.strokeWidth) {
            x = x + style.strokeWidth / 2
            y = y + style.strokeWidth / 2
            width = width - style.strokeWidth
            height = height - style.strokeWidth
        }

        const p0 = { x: x, y: y }
        const p1 = { x: x + width, y: y }
        const p2 = { x: x + width + height / 2, y: y }
        const p3 = { x: x + width + height / 2, y: y + height / 2 }
        const p4 = { x: x + width + height / 2, y: y + height }
        const p5 = { x: x + width, y: y + height }
        const p6 = { x: x, y: y + height }

        this.ctx.beginPath()
        this.ctx.moveTo(p0.x, p0.y)
        this.ctx.lineTo(p1.x, p1.y)
        this.ctx.arcTo(p2.x, p2.y, p3.x, p3.y, height / 2)
        this.ctx.arcTo(p4.x, p4.y, p5.x, p5.y, height / 2)
        this.ctx.lineTo(p6.x, p6.y)
        this.ctx.closePath()

        if (style.fill) {
            this.ctx.fillStyle = style.fill
            this.ctx.fill()
        }

        if (style.stroke) {
            this.ctx.lineWidth = style.strokeWidth ?? 0
            this.ctx.strokeStyle = style.stroke
            this.ctx.stroke()
        }
    }

    /**
     * 
     * @param {Text} text
     * @param {CanvasTextAlign} [align='left']
     * @param {CanvasTextBaseline} [baseline='alphabetic']
     * @returns {TextMetrics}
     */
    getTextMetrix(text, align, baseline) {
        this.ctx.font = text.font.toString()
        this.ctx.textAlign = align ?? 'left'
        this.ctx.textBaseline = baseline ?? 'alphabetic'
        return this.ctx.measureText(text.txt.toString())
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {'top' | 'bottom'} direction
     * @param {Style} style
     * @param {number} roundWidth
     */
    drawRounded90Angle(x, y, width, height, direction, style, roundWidth) {
        let points = null
        switch (direction) {
            case 'top': points = [
                    [x, y],
                    [x + width - roundWidth, y],
                    [x + width, y], [x + width, y - roundWidth],
                    [x + width, y - height]
                ]
                break
            case 'bottom':
            default: points = [
                    [x, y],
                    [x + width - roundWidth, y],
                    [x + width, y], [x + width, y + roundWidth],
                    [x + width, y + height]
                ]
                break
        }
        if (points) {
            this.ctx.beginPath()
            this.ctx.moveTo(points[0][0], points[0][1])
            this.ctx.lineTo(points[1][0], points[1][1])
            this.ctx.quadraticCurveTo(points[2][0], points[2][1], points[3][0], points[3][1])
            this.ctx.lineTo(points[4][0], points[4][1])
            this.ctx.lineWidth = style.strokeWidth ?? 0
            if (style.stroke) this.ctx.strokeStyle = style.stroke
            this.ctx.stroke()
        }
    }
}

/**
 * 
 * @param {TextModel} model
 * @param {string | number} txt
 * @returns {Text}
 */
export function textFromModel(model, txt) {
    return { txt, style: model.style, font: model.font }
}

/**
 * @param {String} name font name
 * @param {Number} size font size in px
 * @param {String} wheight wheight of the font (200, 300, 400, 500, 600, 700...)
 * @param {Number} [lineHeight] line Height in percent (default : 120%)
 */
export function Font(name, size, wheight, lineHeight) {
    this.name = name
    this.size = size
    this.wheight = wheight
    if (size && lineHeight) this.lineHeight = size * lineHeight / 100
    else this.lineHeight = size * defaultLineHeightPercent / 100
    this.toString = () => {
        return this.wheight + ' ' + this.size + 'px ' + this.name
    }
}
