import drawSpanishDiagnosticLabel from "./drawSpanishDiagnosticLabel.js";
import { drawFrenchDiagnosticLabel, drawNotConcernedFrenchDiagnosticLabel } from "./drawFrenchDiagnosticLabel.js";
import { drawFrenchGesLabel, drawNotConcernedFrenchGesLabel } from "./drawFrenchGesLabel.js";
import { loadAssets } from "./helpers.js";

export default function DiagnosticLabelGenerator(libraryPath, version) {
  this.noMargin = false; // true for no default margin
  this.strainerImage = null;

  this.loadWebFonts = function () {
    return loadAssets(libraryPath, this.strainerImage).then((strainerImage) => {
      if (strainerImage) this.strainerImage = strainerImage
    })
  }

  /**
   * @param {HTMLCanvasElement | import('canvas').Canvas} cnvs
   * @param {string | null} [energeticClass]
   * @param {number | string | null} [consumption]
   * @param {number | string | null} [emissions]
   * @param {number | null} [fixedWidth]
   * @param {number | null} [fixedHeight]
   * @param {boolean | null} [isNotConcerned]
   * @param {number | string | null} [finalConsumption]
   * @param {string | null} [emissionsClass]
   * @param {import("./helpers.js").Country | null} [country='FR']
   * @param {import("./helpers.js").Lang | null} [lang]
   */
  this.drawDPE = function (cnvs, energeticClass, consumption, emissions, fixedWidth, fixedHeight, isNotConcerned, finalConsumption, emissionsClass, country, lang) {
    if(typeof country !== 'string') {
      country = 'FR';
    }

    switch (country.toUpperCase().trim()) {
      case 'ES':
        return drawSpanishDiagnosticLabel({
          cnvs,
          consumptionClass: energeticClass,
          consumption,
          emissionsClass,
          emissions,
          fixedWidth,
          fixedHeight,
          lang,
        })
    
      case 'FR':
        return drawFrenchDiagnosticLabel({
          cnvs,
          consumptionClass: energeticClass,
          consumption,
          emissions,
          fixedWidth,
          fixedHeight,
          isNotConcerned,
          finalConsumption,
          noMargin: this.noMargin,
          version,
          strainerImage: this.strainerImage,
          lang
        })
    }
  }

  /**
   * @param {HTMLCanvasElement | import('canvas').Canvas} cnvs
   * @param {number | null} [fixedWidth]
   * @param {number | null} [fixedHeight]
   * @param {import("./helpers.js").Lang | null} [lang] 
   */
  this.drawDPENotConcerned = function (cnvs, fixedWidth, fixedHeight, lang) {
    drawNotConcernedFrenchDiagnosticLabel({ cnvs, fixedWidth, fixedHeight, noMargin: this.noMargin, version, lang })
  }

  /**
   * @param {HTMLCanvasElement | import('canvas').Canvas} cnvs
   * @param {string | null} [climaticClass]
   * @param {number | string | null} [emissions]
   * @param {number | null} [fixedWidth]
   * @param {number | null} [fixedHeight]
   * @param {boolean | null} [isNotConcerned]
   * @param {import("./helpers.js").Lang | null} [lang]
   */
  this.drawGES = function (cnvs, climaticClass, emissions, fixedWidth, fixedHeight, isNotConcerned, lang) {
    drawFrenchGesLabel({
      cnvs,
      emissionsClass: climaticClass,
      emissions,
      fixedWidth,
      fixedHeight,
      isNotConcerned,
      noMargin: this.noMargin,
      version,
      lang,
    })
  }

  /**
   * @param {HTMLCanvasElement | import('canvas').Canvas} cnvs
   * @param {number | null} [fixedWidth]
   * @param {number | null} [fixedHeight]
   * @param {import("./helpers.js").Lang | null} [lang]
   */
  this.drawGESNotConcerned = function (cnvs, fixedWidth, fixedHeight, lang) {
    drawNotConcernedFrenchGesLabel({
      cnvs,
      fixedWidth,
      fixedHeight,
      noMargin: this.noMargin,
      version,
      lang,
    })
  }
}
