export default function DiagnosticLabelGenerator(libraryPath, version) {
  libraryPath = libraryPath || '';
  let ctx = {},
  defaultLineHeightPercent = 120,
  dpeColors = {
    dark: '#1c1c1b',
    grey: '#9d9c9c',
    letters: '#fff',
    white: '#fff'
  },
  dpeFonts = {
    small: new Font('IBMPlexSans', 7, '500'),
    smallCondensed: new Font('IBMPlexSansCondensed', 7, '500', 140),
    letters: new Font('IBMPlexSans-bold', 20, '700'),
    currentLetter: new Font('IBMPlexSans-bold', 43, '700'),
    scores: new Font('IBMPlexSansCondensed-bold', 20, '700'),
    asterisk: new Font('IBMPlexSansCondensed-bold', 9, '700'),
    notConcerned: new Font('IBMPlexSans-bold', 25, '700'),
  },
  dpeStyles = {
    dark: new Style(dpeColors.dark),
    grey: new Style(dpeColors.grey),
    letters: new Style(dpeColors.letters),
    white: new Style(dpeColors.white)
  },
  dpeStroke = new Style(null, dpeColors.dark, 1.5),
  dpeConf = {
    cnvsWidth: 330.95,
    cnvsHeight: 258.66,
    x : 12,
    y : 22,
    width : 46,
    height : 23.5,
    arrow : 8,
    gap : 3,
    widthIncrement : 18.2,
    stroke: dpeColors.dark,
    offset: 98.7,
    greyLine: new Style(null, dpeColors.grey, dpeStroke.strokeWidth),
    sepparator: {
      style: dpeStroke,
      offset: 53,
    },
    centerSepparatorStyle: dpeStroke,
    strokeWidth: 1.5,
    roundWidth: 5,
    passoireIcon: {
      src: 'passoire-energetique.png',
      imgWidth: 40,
      circleRadius: 25,
      lineRadius: 8,
      style: dpeStroke,
      F: {
        offsetX: 15.3,
        offsetY: 43.9
      },
      G: {
        offsetX: 9,
        offsetY: 70.4
      }
    },
    passoireMark: {
      gap: 4.5,
      textGap: 8.5
    },
    perfTextGap: 5,
    scoreBaselinePercentFromTop: 57.8,
    lettersGap: { x: 6, y: 4 },
    currentLetter: {
      style: new Style(dpeColors.letters, dpeStroke.stroke, 1.8),
      rowStyle: dpeStroke,
      letterGap: { x: 6, y: 8 },
      labelGap: 2,
      unitGap: 3
    },
    notConcernedGap: 4,
    texts: {
      goodPerf: new Text('logement extrêmement performant', new Style('#00a06d'), dpeFonts.small),
      badPerf: new Text('logement extrêmement peu performant', new Style('#d7221f'), dpeFonts.small),
      passoire: new Text('passoire', dpeStyles.grey, dpeFonts.small),
      energetique: new Text('énergétique',dpeStyles.grey,dpeFonts.small),
      consommation: new Text('consommation', dpeStyles.dark, dpeFonts.smallCondensed),
      primaryEnergy: new Text('(énergie primaire)', dpeStyles.grey, dpeFonts.smallCondensed),
      consommationScore: new Text('0', dpeStyles.dark, dpeFonts.scores),
      consommationUnit: new Text('kWh/m²/an', dpeStyles.dark, dpeFonts.smallCondensed),
      emissions: new Text('émissions', dpeStyles.dark, dpeFonts.smallCondensed),
      emissionsScore: new Text('0', dpeStyles.dark, dpeFonts.scores),
      emissionAsterisk: new Text('*', dpeStyles.dark, dpeFonts.asterisk),
      emissionsUnit: new Text('kg CO₂/m²/an', dpeStyles.dark, dpeFonts.smallCondensed),
      notConcerned: new Text('BIEN NON SOUMIS', dpeStyles.dark, dpeFonts.notConcerned),
      notConcerned2: new Text('AU DPE', dpeStyles.dark, dpeFonts.notConcerned),
      finalEnergyUnit: new Text('kWh/m²/an', dpeStyles.grey, dpeFonts.small),
      ofFinalEnergy: new Text('d\'énergie finale', dpeStyles.grey, dpeFonts.small),
    }
  },
  dpeVersions = {
    white: {
      sepparator: { style: new Style(dpeStroke.fill, dpeColors.white, dpeStroke.strokeWidth) },
      currentLetter: {
        rowStyle: { stroke: dpeColors.white },
      },
      centerSepparatorStyle: new Style(dpeStroke.fill, dpeColors.white, dpeStroke.strokeWidth),
      passoireIcon: { style: new Style(dpeStroke.fill, dpeColors.white, dpeStroke.strokeWidth)},
      texts: {
        consommation: { style: dpeStyles.white },
        consommationScore: { style: dpeStyles.white },
        consommationUnit: { style: dpeStyles.white },
        emissions: { style: dpeStyles.white },
        emissionsScore: { style: dpeStyles.white },
        emissionAsterisk: { style: dpeStyles.white },
        emissionsUnit: { style: dpeStyles.white },
        notConcerned: { style: dpeStyles.white },
        notConcerned2: { style: dpeStyles.white },
      }
    },
  },
  dpeNoMarginConf = {
    cnvsWidth: 307,
    cnvsHeight: 245,
    x: 1,
    y: 18
  },
  dpeLetters = {
    A: {letter: 'A', color: '#00a06d'},
    B: {letter: 'B', color: '#52b153'},
    C: {letter: 'C', color: '#a5cc74'},
    D: {letter: 'D', color: '#f4e70f'},
    E: {letter: 'E', color: '#f0b40f'},
    F: {letter: 'F', color: '#eb8235'},
    G: {letter: 'G', color: '#d7221f'}
  },
  gesColors = {
    dark: '#1c1c1b',
    grey: '#9d9c9c',
    letters: '#fff',
    white: '#fff',
  },
  gesFonts = {
    title: new Font('IBMPlexSans-bold', 9, '700'),
    small: new Font('IBMPlexSans', 7, '500'),
    smallCondensed: new Font('IBMPlexSansCondensed', 7, '500'),
    letters: new Font('IBMPlexSans', 9, '500'),
    currentLetter: new Font('IBMPlexSans-bold', 19, '700'),
    scores: new Font('IBMPlexSansCondensed-bold', 13, '700'),
    unit: new Font('IBMPlexSansCondensed', 5, '500')
  },
  gesStyles = {
    dark: new Style(gesColors.dark),
    grey: new Style(gesColors.grey),
    letters: new Style(gesColors.letters),
    white: new Style(gesColors.white)
  },
  gesStroke = new Style(null, '#1d1d1b', 1),
  gesConf = {
    cnvsWidth: 189.18,
    cnvsHeight: 258.66,
    x : 14.721,
    y : 24.143,
    width : 17.932,
    height : 10.556,
    gap : 1.482,
    widthIncrement : 8.372,
    scoreLine: gesStroke,
    currentLetter: {
      style:  new Style(gesColors.letters, gesStroke.stroke, gesStroke.strokeWidth),
      rowStyle: new Style(null, gesStroke.stroke, gesStroke.strokeWidth),
      widthIncrementPercent: 76.2
    },
    offsetX: 10.622,
    offsetY: 48.941,
    contour: {
      color: '#a3daf7',
      width: 156.022,
      height: 169.996,
      strokeWidth: 1.5,
      radius: 5,
    },
    perfTextGap: 3,
    texts: {
      title1: new Text('* Dont émissions de gaz', gesStyles.dark, gesFonts.title),
      title2: new Text('à effet de serre', gesStyles.dark, gesFonts.title),
      goodEmissions: new Text('peu d’émissions de CO₂', new Style('#8bb3d3'), gesFonts.small),
      badEmissions1: new Text('émissions de CO₂', new Style('#271b35'), gesFonts.small),
      badEmissions2: new Text('très importantes', new Style('#271b35'), gesFonts.small),
      emissionsScore: new Text('0', gesStyles.dark, gesFonts.scores),
      emissionsUnit: new Text('kg CO₂/m²/an', gesStyles.dark, gesFonts.unit)
    }
  },
  gesVersions = {
    white: {
      scoreLine: { stroke: '#fff' },
      currentLetter: { rowStyle: { stroke: '#fff' } },
      texts: {
        title1: { style: gesStyles.white },
        title2: { style: gesStyles.white },
        badEmissions1: { style: gesStyles.white },
        badEmissions2: { style: gesStyles.white },
        emissionsScore: { style: gesStyles.white },
        emissionsUnit: { style: gesStyles.white }
      }
    }
  },
  gesNoMarginConf = {
    cnvsWidth: 160,
    cnvsHeight: 174,
    x : 2,
    y : 2
  },
  gesLetters = {
    A: {letter: 'A', color: '#a3daf8', scoreLineLength: 21.26},
    B: {letter: 'B', color: '#8cb4d3', scoreLineLength: 21.26},
    C: {letter: 'C', color: '#7792b1', scoreLineLength: 21.26},
    D: {letter: 'D', color: '#606f8f', scoreLineLength: 21.26},
    E: {letter: 'E', color: '#4d5271', scoreLineLength: 21.26},
    F: {letter: 'F', color: '#393551', scoreLineLength: 15.52},
    G: {letter: 'G', color: '#281b35', scoreLineLength: 6.11}
  };

  if(typeof version === 'string' && typeof dpeVersions[version] === 'object') {
    dpeConf = recursiveMerge(dpeConf, dpeVersions[version]);
  }

  if(typeof version === 'string' && typeof gesVersions[version] === 'object') {
    gesConf = recursiveMerge(gesConf, gesVersions[version]);
  }

  this.noMargin = false; // true for no default margin
  this.strainerImage = null;


  this.loadWebFonts = function() {

    const fonts = [
      new FontFace('IBMPlexSans', 'url(' + libraryPath + 'fonts/IBMPlexSans-Regular.otf)', { weight: 500 }),
      new FontFace('IBMPlexSans-bold', 'url(' + libraryPath + 'fonts/IBMPlexSans-Bold.otf)', { weight: 700 }),
      new FontFace('IBMPlexSansCondensed', 'url(' + libraryPath + 'fonts/IBMPlexSansCondensed-Regular.otf)', { weight: 500 }),
      new FontFace('IBMPlexSansCondensed-bold', 'url(' + libraryPath + 'fonts/IBMPlexSansCondensed-Bold.otf)', { weight: 700 })
    ];

    let promises = fonts.map((font) => font.load().then((font) => document.fonts.add(font)));

    if (!this.strainerImage) {
      promises.push(loadImage(dpeConf.passoireIcon.src).then((img) => this.strainerImage = img));
    }

    return Promise.allSettled(promises);
  }


  this.drawDPE = function(cnvs, energeticClass, consumption, emissions, fixedWidth, fixedHeight, isNotConcerned, finalConsumption) {
      if(isNotConcerned) {
        energeticClass = 'D';
        consumption = '-';
        emissions = '-';
        finalConsumption = '-';
      }
      const lettersArray = Object.entries(dpeLetters),
      x = this.noMargin ? dpeNoMarginConf.x : dpeConf.x,
      y = this.noMargin ? dpeNoMarginConf.y : dpeConf.y,
      cnvsWidth = this.noMargin ? dpeNoMarginConf.cnvsWidth : dpeConf.cnvsWidth,
      cnvsHeight = this.noMargin ? dpeNoMarginConf.cnvsHeight : dpeConf.cnvsHeight,
      width = dpeConf.width,
      height = dpeConf.height,
      arrow = dpeConf.arrow,
      gap = dpeConf.gap,
      widthIncrement = dpeConf.widthIncrement,
      lineX = x + dpeConf.offset,
      currentLetterHeight = 2 * height,
      currentLetterArrow = 2 * arrow,
      graphHeight = (lettersArray.length-1) * (height + gap) + currentLetterHeight,
      currentLetterTxtStyle = new Text(energeticClass, dpeConf.currentLetter.style, dpeFonts.currentLetter),
      consumptionX = x + dpeConf.sepparator.offset/2,
      emissionX = lineX - (dpeConf.offset - dpeConf.sepparator.offset)/2,
      textConsommationScore = textFromModel(dpeConf.texts.consommationScore, consumption),
      textEmissionsScore = textFromModel(dpeConf.texts.emissionsScore, emissions);

      let afterCurrentLetterOffsetH = 0,
      afterCurrentLetterOffsetW = 0;
      
      let scale = autosizeCanvas(cnvs, cnvsWidth, cnvsHeight, fixedWidth, fixedHeight);
  
      ctx = cnvs.getContext('2d');
      ctx.scale(scale, scale);

      if(isNotConcerned) ctx.globalAlpha = 0.2;
  
      drawText(lineX, y - dpeConf.perfTextGap, dpeConf.texts.goodPerf);
      drawText(lineX, y + graphHeight + dpeConf.perfTextGap, dpeConf.texts.badPerf, null, null, 'top');
  
      if(energeticClass != 'F' && energeticClass != 'G') {
        drawLine([lineX - dpeConf.passoireMark.gap, y + graphHeight - height*2 - gap], [lineX - dpeConf.passoireMark.gap, y + graphHeight], dpeConf.greyLine);
        drawText(lineX - (dpeConf.passoireMark.gap + dpeConf.passoireMark.textGap), y + graphHeight - height - gap, dpeConf.texts.passoire, null, 'right');
        drawText(lineX - (dpeConf.passoireMark.gap + dpeConf.passoireMark.textGap), y + graphHeight - height, dpeConf.texts.energetique, null, 'right', 'top');
      }
  
      for(let i = 0; lettersArray.length > i; i++) {
        const lineY = y + i*(height + gap) + afterCurrentLetterOffsetH,
        lineWidth = width + widthIncrement*i + afterCurrentLetterOffsetW;
        if(energeticClass == lettersArray[i][1].letter) {
          const scoresY = lineY + currentLetterHeight * dpeConf.scoreBaselinePercentFromTop/100;
          drawLetterRow(lineX, lineY, lineWidth, currentLetterHeight, currentLetterArrow, lettersArray[i][1].color);
          drawLine([lineX, lineY], [lineX, lineY + currentLetterHeight], dpeConf.centerSepparatorStyle);
          drawLine([x + dpeConf.sepparator.offset,lineY + dpeConf.strokeWidth*3],
            [x + dpeConf.sepparator.offset,lineY + currentLetterHeight - dpeConf.sepparator.style.strokeWidth*3],
            dpeConf.sepparator.style);
          drawRoundedLetterRow(x, lineY, lineWidth + dpeConf.offset, currentLetterHeight, currentLetterArrow, dpeConf.currentLetter.rowStyle, dpeConf.roundWidth);
          drawText(lineX + dpeConf.currentLetter.letterGap.x, lineY + currentLetterHeight - dpeConf.currentLetter.letterGap.y, currentLetterTxtStyle, true);
          drawText(consumptionX, lineY - dpeConf.currentLetter.labelGap, dpeConf.texts.primaryEnergy, null, "center", "bottom");
          drawText(consumptionX, lineY - dpeConf.texts.primaryEnergy.font.lineHeight, dpeConf.texts.consommation, null, "center", "bottom");
          drawText(consumptionX, scoresY, textConsommationScore, null, "center");
          drawText(consumptionX, scoresY + dpeConf.currentLetter.unitGap, dpeConf.texts.consommationUnit, null, "center", "top");

          if(finalConsumption && typeof finalConsumption !== 'undefined') {
            const finalEnergyTetxStyle = textFromModel(
              dpeConf.texts.finalEnergyUnit,
              finalConsumption + ' ' + dpeConf.texts.finalEnergyUnit.txt
            )
  
            drawText(consumptionX, lineY + currentLetterHeight + dpeConf.currentLetter.labelGap, finalEnergyTetxStyle, null, "center", "top");
            drawText(consumptionX, lineY + currentLetterHeight + dpeConf.currentLetter.labelGap + finalEnergyTetxStyle.font.lineHeight, dpeConf.texts.ofFinalEnergy, null, "center", "top");
          }

          drawText(emissionX, lineY - dpeConf.currentLetter.labelGap, dpeConf.texts.emissions, null, "center", "bottom");
          const asteriskMetics = getTextMetrix(dpeConf.texts.emissionAsterisk),
          gesTxtMetics = drawText(emissionX - asteriskMetics.width/2, scoresY, textEmissionsScore, null, "center", null, true);
          if(!isNotConcerned) drawText(emissionX - asteriskMetics.width/2 + gesTxtMetics.width/2, scoresY - gesTxtMetics.actualBoundingBoxAscent, dpeConf.texts.emissionAsterisk, null, null, "top");
          drawText(emissionX, scoresY + dpeConf.currentLetter.unitGap, dpeConf.texts.emissionsUnit, null, "center", "top");
  
          if(energeticClass == 'F' || energeticClass == 'G') {
            drawRounded90Angle(lineX + lineWidth + currentLetterArrow, lineY + height, dpeConf.passoireIcon[energeticClass].offsetX, dpeConf.passoireIcon[energeticClass].offsetY, 'top', dpeConf.passoireIcon.style, dpeConf.passoireIcon.lineRadius);
            drawCircle(lineX + lineWidth + currentLetterArrow + dpeConf.passoireIcon[energeticClass].offsetX, lineY + height - dpeConf.passoireIcon[energeticClass].offsetY - dpeConf.passoireIcon.circleRadius, dpeConf.passoireIcon.circleRadius, dpeConf.passoireIcon.style);

            if (this.strainerImage) {
              ctx.drawImage(this.strainerImage,
                lineX + lineWidth + currentLetterArrow + dpeConf.passoireIcon[energeticClass].offsetX - dpeConf.passoireIcon.imgWidth/2,
                lineY + height - dpeConf.passoireIcon[energeticClass].offsetY - dpeConf.passoireIcon.circleRadius - dpeConf.passoireIcon.imgWidth/2,
                dpeConf.passoireIcon.imgWidth,
                dpeConf.passoireIcon.imgWidth);
            }
          }
          afterCurrentLetterOffsetH = currentLetterHeight - height;
          afterCurrentLetterOffsetW = widthIncrement;
        } else {
          drawLetterRow(lineX, lineY, lineWidth, height, arrow, lettersArray[i][1].color);
          drawText(lineX + dpeConf.lettersGap.x, lineY + height - dpeConf.lettersGap.y, new Text(lettersArray[i][1].letter, dpeStyles.letters, dpeFonts.letters));
        }
      }

      if(isNotConcerned) {
        ctx.globalAlpha = 1;
        drawText(cnvsWidth / 2, (cnvsHeight / 2) - (dpeConf.notConcernedGap / 2), dpeConf.texts.notConcerned, false, 'center', 'bottom');
        drawText(cnvsWidth / 2, (cnvsHeight / 2) + (dpeConf.notConcernedGap / 2), dpeConf.texts.notConcerned2, false, 'center', 'top');
      }
  }

  this.drawDPENotConcerned = function(cnvs, fixedWidth, fixedHeight) {
    return this.drawDPE(cnvs, null, null, null, fixedWidth, fixedHeight, true);
  }

  this.drawGES = function(cnvs, climaticClass, emissions, fixedWidth, fixedHeight, isNotConcerned) {
    if(isNotConcerned) climaticClass = 'D';
    const lettersArray = Object.entries(gesLetters),
      x = this.noMargin ? gesNoMarginConf.x : gesConf.x,
      y = this.noMargin ? gesNoMarginConf.y : gesConf.y,
      cnvsWidth = this.noMargin ? gesNoMarginConf.cnvsWidth : gesConf.cnvsWidth,
      cnvsHeight = this.noMargin ? gesNoMarginConf.cnvsHeight : gesConf.cnvsHeight,
      width = gesConf.width,
      height = gesConf.height,
      gap = gesConf.gap,
      offsetX = gesConf.offsetX,
      offsetY = gesConf.offsetY,
      widthIncrement = gesConf.widthIncrement,
      currentLetterHeight = 2 * height,
      graphHeight = (lettersArray.length-1) * (height + gap) + currentLetterHeight,
      lineX = x + offsetX,
      textEmissionsScore = textFromModel(gesConf.texts.emissionsScore, emissions);

      let afterCurrentLetterOffsetH = 0, afterCurrentLetterOffsetW = 0;
      
      let scale = autosizeCanvas(cnvs, cnvsWidth, cnvsHeight, fixedWidth, fixedHeight);
  
      ctx = cnvs.getContext('2d');
      ctx.scale(scale, scale);

      if(isNotConcerned) ctx.globalAlpha = 0.2;
  
      drawRoundedRectangle(x, y, gesConf.contour.width, gesConf.contour.height, gesConf.contour.radius,
      new Style(null, gesConf.contour.color, gesConf.contour.strokeWidth));
  
      drawText(lineX, y + offsetX, gesConf.texts.title1, null, null, 'top');
      drawText(lineX, y + offsetX + gesConf.texts.title1.font.lineHeight, gesConf.texts.title2, null, null, 'top');
  
      drawText(lineX, y + offsetY - gesConf.perfTextGap, gesConf.texts.goodEmissions);
      drawText(lineX, y + offsetY + graphHeight + gesConf.perfTextGap, gesConf.texts.badEmissions1, null, null, 'top');
      drawText(lineX, y + offsetY + graphHeight + gesConf.perfTextGap + gesConf.texts.badEmissions1.font.lineHeight, gesConf.texts.badEmissions2, null, null, 'top');
  
      for(let i = 0; lettersArray.length > i; i++) {
        const lineY = y + offsetY + i*(height + gap) + afterCurrentLetterOffsetH,
        lineWidth = width + widthIncrement*i + afterCurrentLetterOffsetW;
        if(climaticClass == lettersArray[i][1].letter) {
          const currentLetterLineWidth = lineWidth - ((100 - gesConf.currentLetter.widthIncrementPercent)/100) * height,
          currentLetterRowEndX = lineX + currentLetterLineWidth + currentLetterHeight/2,
          currentLetterRowMidY = lineY + currentLetterHeight/2,
          scoreLineGap = gesConf.currentLetter.style.strokeWidth*2;
          drawGesLetterRow(lineX, lineY, currentLetterLineWidth, currentLetterHeight,  new Style(lettersArray[i][1].color, gesConf.currentLetter.rowStyle.stroke, gesConf.currentLetter.rowStyle.strokeWidth), true);
          drawText(lineX + 3, lineY + currentLetterHeight - 4, new Text(lettersArray[i][1].letter, gesConf.currentLetter.style, gesFonts.currentLetter), true);
          if(!isNotConcerned) {
            drawLine(
              [currentLetterRowEndX + scoreLineGap, currentLetterRowMidY],
              [currentLetterRowEndX + lettersArray[i][1].scoreLineLength + scoreLineGap, currentLetterRowMidY],
              gesConf.scoreLine);
            const scoreTxtMetics = drawText(
              currentLetterRowEndX + lettersArray[i][1].scoreLineLength + scoreLineGap*2,
              currentLetterRowMidY, textEmissionsScore, null, null, 'middle', true);
            drawText(
              currentLetterRowEndX + lettersArray[i][1].scoreLineLength + scoreLineGap*2 + scoreTxtMetics.width + 1,
              currentLetterRowMidY + scoreTxtMetics.actualBoundingBoxDescent, gesConf.texts.emissionsUnit);
          }
          afterCurrentLetterOffsetH = currentLetterHeight - height;
          afterCurrentLetterOffsetW = widthIncrement; 
        } else {
          drawGesLetterRow(lineX, lineY, lineWidth, height, new Style(lettersArray[i][1].color));
          drawText(lineX + 2, lineY + height - 2.136, new Text(lettersArray[i][1].letter, gesStyles.letters, gesFonts.letters));
        }
      }
  }

  this.drawGESNotConcerned = function(cnvs, fixedWidth, fixedHeight) {
    return this.drawGES(cnvs, null, null, fixedWidth, fixedHeight, true);
  }

  function autosizeCanvas(cnvs, cnvsWidth, cnvsHeight, fixedWidth, fixedHeight) {
    let scale = 1, scaleX, scaleY;
    if(fixedWidth && fixedHeight) {
      scaleX = fixedWidth / cnvsWidth;
      scaleY = fixedHeight / cnvsHeight;
      scale = Math.min(scaleX, scaleY);
      cnvs.width = fixedWidth;
      cnvs.height = fixedHeight;
    } else if (fixedWidth) {
      scale = fixedWidth / cnvsWidth;
      cnvs.width = fixedWidth;
      cnvs.height = cnvsHeight * scale;
    } else if (fixedHeight) {
      scale = fixedHeight / cnvsHeight;
      cnvs.width = cnvsWidth * scale;
      cnvs.height = fixedHeight;
    } else if (cnvs.width && cnvs.height) {
      scaleX = cnvs.width / cnvsWidth;
      scaleY = cnvs.height / cnvsHeight;
      scale = Math.min(scaleX, scaleY);
    } else {
      cnvs.width = cnvsWidth;
      cnvs.height = cnvsHeight;
    }
    return scale;
  }

  function drawPolyline(style, points) {
    const firstPoint = points[0];
    ctx.beginPath();
    ctx.moveTo(firstPoint[0], firstPoint[1]);
    points.shift();
    points.forEach((point) => { ctx.lineTo(point[0], point[1]); });

    if(style.stroke) {
      ctx.lineWidth = style.strokeWidth;
      ctx.strokeStyle = style.stroke;
      ctx.stroke();
    }
  }

  function drawLine(a, b, style) {
    drawPolyline(style, [a,b]);
  }

  function drawPolygon(style, points) {
    drawPolyline(new Style(), points);
    ctx.closePath();
    if(style.fill) {
      ctx.fillStyle = style.fill;
      ctx.fill();
    } 
    if(style.stroke) {
      ctx.lineWidth = style.strokeWidth;
      ctx.strokeStyle = style.stroke;
      ctx.stroke();
    }
  }

  function drawCircle(x, y, radius, style) {
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, Math.PI * 2, true);  
    if(style.fill) {
      ctx.fillStyle = style.fill;
      ctx.fill();
    }
    if(style.stroke) {
      ctx.lineWidth = style.strokeWidth;
      ctx.strokeStyle = style.stroke;
      ctx.stroke();
    }
  }

  function drawLetterRow(x, y, width, height, arrow, color) {
    drawPolygon(new Style(color), [
      [x, y],
      [x + width, y],
      [x + width + arrow, y + height / 2],
      [x + width, y + height],
      [x, y + height]
    ]);
  }

  function drawRoundedLetterRow(x, y, width, height, arrow, style, roundWidth) {
    x = x + style.strokeWidth / 2;
    y = y + style.strokeWidth / 2;
    width = width - style.strokeWidth / 2;
    height = height - style.strokeWidth;
    const points = [
      [x, y],
      [x + width, y],
      [x + width + arrow, y + height / 2],
      [x + width, y + height],
      [x, y + height]
    ],
    firstPoint = points[0],
    lastPoint = points[points.length - 1];

    points.shift();
    points.pop();

    ctx.beginPath();
    ctx.moveTo(firstPoint[0], firstPoint[1] + roundWidth);
    if(roundWidth != 0) ctx.quadraticCurveTo(firstPoint[0], firstPoint[1], firstPoint[0] + roundWidth, firstPoint[1]);

    points.forEach((point) => { ctx.lineTo(point[0], point[1]); });

    ctx.lineTo(lastPoint[0] + roundWidth, lastPoint[1]);
    if(roundWidth != 0) {
      ctx.quadraticCurveTo(lastPoint[0], lastPoint[1], lastPoint[0], lastPoint[1]  - roundWidth);
      ctx.closePath();
    }

    if(style.fill) {
      ctx.fillStyle = style.fill;
      ctx.fill();
    }

    if(style.stroke) {
      ctx.lineWidth = style.strokeWidth;
      ctx.strokeStyle = style.stroke;
      ctx.stroke();
    }
  }

  function drawGesLetterRow(x, y, width, height, style, innerStroke) {
    if(innerStroke && style.stroke) {
      x = x + style.strokeWidth / 2;
      y = y + style.strokeWidth / 2;
      width = width - style.strokeWidth;
      height = height - style.strokeWidth;
    }

    const p0 = {x: x, y: y},
    p1 = {x: x + width, y: y},
    p2 = {x: x + width + height/2, y: y},
    p3 = {x: x + width + height/2, y: y + height/2},
    p4 = {x: x + width + height/2, y: y + height},
    p5 = {x: x + width, y: y + height},
    p6 = {x: x, y: y + height};

    ctx.beginPath();
    ctx.moveTo(p0.x, p0.y);
    ctx.lineTo(p1.x, p1.y);
    ctx.arcTo(p2.x, p2.y, p3.x, p3.y, height/2);
    ctx.arcTo(p4.x, p4.y, p5.x, p5.y, height/2);
    ctx.lineTo(p6.x, p6.y);
    ctx.closePath();

    if(style.fill) {
      ctx.fillStyle = style.fill;
      ctx.fill();
    }

    if(style.stroke) {
      ctx.lineWidth = style.strokeWidth;
      ctx.strokeStyle = style.stroke;
      ctx.stroke();
    }
  }

  function drawText(x, y, text, outerStroke, align, baseline, metrix) {
    ctx.font = text.font.toString();
    ctx.fillStyle = text.style.fill ?? '#000';
    ctx.strokeStyle = text.style.stroke ?? '#000';
    ctx.textAlign = align ?? 'left';
    ctx.textBaseline = baseline ?? 'alphabetic';
    if(text.style.stroke && text.style.strokeWidth) ctx.lineWidth = outerStroke ? text.style.strokeWidth * 2 : text.style.strokeWidth;
    ctx.fillText(text.txt, x, y);
    if(text.style.stroke && text.style.strokeWidth) {
      if(outerStroke) {
        ctx.strokeText(text.txt, x, y);
        ctx.save();
        ctx.globalCompositeOperation = 'destination-out';
        ctx.globalAlpha = 1;
        ctx.fillStyle = "#000";
        ctx.fillText(text.txt, x, y);
        ctx.restore();
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillText(text.txt, x, y);
        ctx.restore();
      } else {
        ctx.save();
        ctx.globalCompositeOperation = 'destination-out';
        ctx.globalAlpha = 1;
        ctx.strokeStyle = "#000";
        ctx.strokeText(text.txt, x, y);
        ctx.restore();
        ctx.strokeText(text.txt, x, y);
      }
    }
    if(metrix) return ctx.measureText(text.txt);
  }

  function getTextMetrix(text, align, baseline) {
    ctx.font = text.font;
    ctx.textAlign = align ?? 'left';
    ctx.textBaseline = baseline ?? 'alphabetic';
    return ctx.measureText(text.txt);
  }

  function drawRounded90Angle(x, y, width, height, direction, style, roundWidth) {
    let points = null;
    switch (direction) {
      case 'top': points = [
          [x,y],
          [x + width - roundWidth, y],
          [x + width, y], [x + width, y - roundWidth],
          [x + width, y - height]
        ];
        break;
      case 'bottom':
      default : points = [
          [x,y],
          [x + width - roundWidth, y],
          [x + width, y], [x + width, y + roundWidth],
          [x + width, y + height]
        ];
        break;
    }
    if(points) {
      ctx.beginPath();
      ctx.moveTo(points[0][0], points[0][1]);
      ctx.lineTo(points[1][0], points[1][1]);
      ctx.quadraticCurveTo(points[2][0], points[2][1], points[3][0], points[3][1]);
      ctx.lineTo(points[4][0], points[4][1]);
      ctx.lineWidth = style.strokeWidth;
      ctx.strokeStyle = style.stroke;
      ctx.stroke();
    }
  }

  function drawRoundedRectangle (x, y, width, height, radius, style) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    if(style.fill) {
      ctx.fillStyle = style.fill;
      ctx.fill();
    } 
    if(style.stroke) {
      ctx.lineWidth = style.strokeWidth;
      ctx.strokeStyle = style.stroke;
      ctx.stroke();
    }
  }

  function loadImage(src) {
    const img = new Image();
    img.src = libraryPath + src;
    return img.decode().then(() => img);
  }

  function loadScript(src) {
    return new Promise(function(resolve, reject) {
      const s = document.createElement('script');
      let r = false;
      s.type = 'text/javascript';
      s.src = src;
      s.async = true;
      s.onerror = function(err) {
        reject(err, s);
      };
      s.onload = s.onreadystatechange = function() {
        if (!r && (!this.readyState || this.readyState == 'complete')) {
          r = true;
          resolve();
        }
      };
      const t = document.getElementsByTagName('script')[0];
      t.parentElement.insertBefore(s, t);
    });
  }

  function textFromModel(text, txt) {
    return new Text(txt, text.style, text.font);
  }
  
  /**
   * text model
   * @param {String} txt Text
   * @param {Style} color CSS-like string representation of a color (HEX, RGB, RGBA, HSL)
   * @param {Font} font CSS-like string representation of a font
   */
  function Text(txt, style, font) {
    this.txt = txt;
    this.style = style;
    this.font = font;
  }

  /**
   * 
   * @param {String} name font name
   * @param {Number} size font size in px
   * @param {String} wheight wheight of the font (200, 300, 400, 500, 600, 700...)
   * @param {Number} lineHeight line Height in percent (default : 120%)
   * @
   */
  function Font(name, size, wheight, lineHeight) {
    this.name = name;
    this.size = size;
    this.wheight = wheight;
    if(size && lineHeight) this.lineHeight = size * lineHeight/100;
    else this.lineHeight = size * defaultLineHeightPercent/100;
    this.toString = () => {
      return this.wheight + ' ' + this.size + 'px ' + this.name;
    };
  }
  
  /**
   * 
   * @param {String} fill backgroung color
   * @param {String} stroke border color
   * @param {Number} strokeWidth thickness of the border
   */
  function Style(fill, stroke, strokeWidth) {
    this.fill = fill;
    this.stroke = stroke;
    this.strokeWidth = strokeWidth;
  }

  function recursiveMerge(...args) {
    let result = {}
    for (const arg of args) {
      if (arg) {
        for (const key in arg) {
          const value = arg[key]
          if (key) {
            if (value && typeof value === 'object') {
              result[key] = recursiveMerge(result[key], value)
            } else {
              result[key] = value
            }
          }
        }
      }
    }
    return result
  }
}
