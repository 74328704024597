import { CanvasDrawer, Font, textFromModel } from "./CanvasDrawer.js"
import { recursiveMerge } from "./helpers.js"

/**
 * @typedef {'goodPerf' | 'badPerf' | 'passoireEnergetique1' | 'passoireEnergetique2' | 'consommation' | 'primaryEnergy' | 'consommationUnit' | 'emissions' | 'emissionsUnit' | 'notConcerned' | 'notConcerned2' | 'finalEnergyUnit' | 'ofFinalEnergy' } TranslationKey
*/

/**
 * @typedef { TranslationKey | 'consommationScore' | 'emissionsScore' } TranslationModelKey
*/

/**
 * @typedef {Record<TranslationKey, string>} Translations
*/

/**
 * @template [T=import('./CanvasDrawer.js').TextModel]
 * @typedef {Record<TranslationModelKey, T>} TextModels
 */

/**
 * @typedef {'white'} Version
 */

/**
 * @typedef {'fr' | 'en' | 'es' | 'pt' | 'it' | 'de' | (string & {})} Lang
 */

const colors = {
  dark: '#1c1c1b',
  grey: '#9d9c9c',
  letters: '#fff',
  white: '#fff'
}

const fonts = {
  small: new Font('IBMPlexSans', 7, '500'),
  smallCondensed: new Font('IBMPlexSansCondensed', 6.5, '500', 140),
  letters: new Font('IBMPlexSans-bold', 20, '700'),
  currentLetter: new Font('IBMPlexSans-bold', 43, '700'),
  scores: new Font('IBMPlexSansCondensed-bold', 20, '700'),
  asterisk: new Font('IBMPlexSansCondensed-bold', 9, '700'),
  notConcerned: new Font('IBMPlexSans-bold', 25, '700'),
}

const styles = {
  dark: { fill: colors.dark },
  grey: { fill: colors.grey },
  letters: { fill: colors.letters },
  white: { fill: colors.white }
}

const stroke = { fill: null, stroke: colors.dark, strokeWidth: 1.5 }

const baseConf = {
  cnvsWidth: 330.95,
  cnvsHeight: 258.66,
  x: 12,
  y: 22,
  width: 46,
  height: 23.5,
  arrow: 8,
  gap: 3,
  widthIncrement: 18.2,
  stroke: colors.dark,
  offset: 98.7,
  greyLine: { fill: null, stroke: colors.grey, strokeWidth: stroke.strokeWidth },
  sepparator: {
    style: stroke,
    offset: 53,
  },
  centerSepparatorStyle: stroke,
  strokeWidth: 1.5,
  roundWidth: 5,
  passoireIcon: {
    src: 'passoire-energetique.png',
    imgWidth: 40,
    circleRadius: 25,
    lineRadius: 8,
    style: stroke,
    F: {
      offsetX: 15.3,
      offsetY: 43.9
    },
    G: {
      offsetX: 9,
      offsetY: 70.4
    }
  },
  passoireMark: {
    gap: 4.5,
    textGap: 8.5
  },
  perfTextGap: 5,
  scoreBaselinePercentFromTop: 57.8,
  lettersGap: { x: 6, y: 4 },
  currentLetter: {
    style: { fill: colors.letters, stroke: stroke.stroke, strokeWidth: 1.8 },
    rowStyle: stroke,
    letterGap: { x: 6, y: 8 },
    labelGap: 2,
    unitGap: 3
  },
  notConcernedGap: 4,
  emissionAsterisk: { txt: '*', style: styles.dark, font: fonts.asterisk },
}

let noMarginConf = {
  cnvsWidth: 307,
  cnvsHeight: 245,
  x: 1,
  y: 18
}

/** @type {Record<Version, any>} */
let versions = {
  white: {
    sepparator: { style: { fill: stroke.fill, stroke: colors.white, strokeWidth: stroke.strokeWidth } },
    currentLetter: {
      rowStyle: { stroke: colors.white },
    },
    centerSepparatorStyle: { fill: stroke.fill, stroke: colors.white, strokeWidth: stroke.strokeWidth },
    passoireIcon: { style: { fill: stroke.fill, stroke: colors.white, strokeWidth: stroke.strokeWidth } },
    emissionAsterisk: { style: styles.white },
  },
}

/** @type {Record<Lang, Translations>} */
const baseTranslations = {
  fr: {
    goodPerf: 'logement extrêmement performant',
    badPerf: 'logement extrêmement peu performant',
    passoireEnergetique1: 'passoire',
    passoireEnergetique2: 'énergétique',
    consommation: 'consommation',
    primaryEnergy: '(énergie primaire)',
    consommationUnit: 'kWh/m²/an',
    emissions: 'émissions',
    emissionsUnit: 'kg CO₂/m²/an',
    notConcerned: 'BIEN NON SOUMIS',
    notConcerned2: 'AU DPE',
    finalEnergyUnit: 'kWh/m²/an',
    ofFinalEnergy: 'd\'énergie finale',
  },
  en: {
    goodPerf: 'extremely high-performing housing',
    badPerf: 'extremely low-performing housing',
    passoireEnergetique1: 'energy',
    passoireEnergetique2: 'strainer',
    consommation: 'consumption',
    primaryEnergy: '(primary energy)',
    consommationUnit: 'kWh/m²/year',
    emissions: 'emissions',
    emissionsUnit: 'kg CO₂/m²/year',
    notConcerned: 'PROPERTY NOT',
    notConcerned2: 'SUBJECT TO DPE',
    finalEnergyUnit: 'kWh/m²/year',
    ofFinalEnergy: 'of final energy',
  },
  es: {
    goodPerf: 'vivienda de alto rendimiento',
    badPerf: 'vivienda de bajo rendimiento',
    passoireEnergetique1: 'colador',
    passoireEnergetique2: 'energético',
    consommation: 'consumo',
    primaryEnergy: '(energía primaria)',
    consommationUnit: 'kWh/m²/año',
    emissions: 'emisiones',
    emissionsUnit: 'kg CO₂/m²/año',
    notConcerned: 'BIENES NO',
    notConcerned2: 'SUJETOS AL DPE',
    finalEnergyUnit: 'kWh/m²/año',
    ofFinalEnergy: 'de energía final',
  },
  pt: {
    goodPerf: 'habitação de alto desempenho',
    badPerf: 'habitação de baixo desempenho',
    passoireEnergetique1: 'peneira',
    passoireEnergetique2: 'energético',
    consommation: 'consumo',
    primaryEnergy: '(energia primária)',
    consommationUnit: 'kWh/m²/ano',
    emissions: 'emissões',
    emissionsUnit: 'kg CO₂/m²/ano',
    notConcerned: 'PROPRIEDADE NÃO',
    notConcerned2: 'SUJEITA AO DPE',
    finalEnergyUnit: 'kWh/m²/ano',
    ofFinalEnergy: 'de energia final',
  },
  it: {
    goodPerf: 'abitazione ad alte prestazioni',
    badPerf: 'abitazione a basse prestazioni',
    passoireEnergetique1: 'setaccio',
    passoireEnergetique2: 'energetico',
    consommation: 'consumo',
    primaryEnergy: '(energia primaria)',
    consommationUnit: 'kWh/m²/anno',
    emissions: 'emissioni',
    emissionsUnit: 'kg CO₂/m²/anno',
    notConcerned: 'IMMOBILE NON',
    notConcerned2: 'SOGGETTO AL DPE',
    finalEnergyUnit: 'kWh/m²/anno',
    ofFinalEnergy: 'di energia finale',
  },
  de: {
    goodPerf: 'sehr leistungsstarke Wohnung',
    badPerf: 'sehr leistungsschwache Wohnung',
    passoireEnergetique1: 'Sieb',
    passoireEnergetique2: 'energetisch',
    consommation: 'Verbrauch',
    primaryEnergy: '(Primärenergie)',
    consommationUnit: 'kWh/m²/Jahr',
    emissions: 'Emissionen',
    emissionsUnit: 'kg CO₂/m²/Jahr',
    notConcerned: 'IMMOBILIE NICHT',
    notConcerned2: 'BETROFFEN VON DER DPE',
    finalEnergyUnit: 'kWh/m²/Jahr',
    ofFinalEnergy: 'von Endenergie',
  },
}

/** @type {TextModels} */
const baseTextModels = {
  goodPerf: { style: { fill: '#00a06d' }, font: fonts.small },
  badPerf: { style: { fill: '#d7221f' }, font: fonts.small },
  passoireEnergetique1: { style: styles.grey, font: fonts.small },
  passoireEnergetique2: { style: styles.grey, font: fonts.small },
  consommation: { style: styles.dark, font: fonts.smallCondensed },
  consommationScore: { style: styles.dark, font: fonts.scores },
  primaryEnergy: { style: styles.grey, font: fonts.smallCondensed },
  consommationUnit: { style: styles.dark, font: fonts.smallCondensed },
  emissions: { style: styles.dark, font: fonts.smallCondensed },
  emissionsScore: { style: styles.dark, font: fonts.scores },
  emissionsUnit: { style: styles.dark, font: fonts.smallCondensed },
  notConcerned: { style: styles.dark, font: fonts.notConcerned },
  notConcerned2: { style: styles.dark, font: fonts.notConcerned },
  finalEnergyUnit: { style: styles.grey, font: fonts.small },
  ofFinalEnergy: { style: styles.grey, font: fonts.small },
}

/** @type {Record<Version, Partial<TextModels<Partial<import('./CanvasDrawer.js').TextModel>>>>} */
let textModelsVersions = {
  white: {
    consommation: { style: styles.white },
    consommationUnit: { style: styles.white },
    consommationScore: { style: styles.white },
    emissions: { style: styles.white },
    emissionsScore: { style: styles.white },
    emissionsUnit: { style: styles.white },
    notConcerned: { style: styles.white },
    notConcerned2: { style: styles.white },
  }
}

let letters = {
  A: { letter: 'A', color: '#00a06d' },
  B: { letter: 'B', color: '#52b153' },
  C: { letter: 'C', color: '#a5cc74' },
  D: { letter: 'D', color: '#f4e70f' },
  E: { letter: 'E', color: '#f0b40f' },
  F: { letter: 'F', color: '#eb8235' },
  G: { letter: 'G', color: '#d7221f' }
}

/**
 * @param {{
 *  cnvs: HTMLCanvasElement | import('canvas').Canvas;
 *  fixedWidth?: number | null;
 *  fixedHeight?: number | null;
 *  consumptionClass?: string | null;
 *  consumption?: number | string | null;
 *  finalConsumption?: number | string | null;
 *  emissionsClass?: string | null;
 *  emissions?: number | string | null;
 *  isNotConcerned?: boolean | null;
 *  strainerImage?: HTMLImageElement | import('canvas').Image | null;
 *  noMargin?: boolean | null;
 *  version?: Version | (string & {}) | null;
 *  lang?: Lang | null;
 * }} config
 */
export function drawFrenchDiagnosticLabel({ cnvs, consumptionClass, consumption, emissions, fixedWidth, fixedHeight, isNotConcerned, finalConsumption, strainerImage, noMargin, version, lang }) {
  if (!lang) lang = 'fr'

  const conf =
    version && typeof versions[version] === 'object'
      ? recursiveMerge(baseConf, versions[version])
      : baseConf

  const textModels =
    version && typeof textModelsVersions[version] === 'object'
      ? recursiveMerge(baseTextModels, textModelsVersions[version])
      : baseTextModels

  const texts = makeTranslationsGetter(baseTranslations[lang] ?? baseTranslations['fr'], textModels)

  if (isNotConcerned) {
    consumptionClass = 'D';
    consumption = '-';
    emissions = '-';
    finalConsumption = '-';
  }

  const lettersArray = Object.entries(letters)
  const x = noMargin ? noMarginConf.x : conf.x
  const y = noMargin ? noMarginConf.y : conf.y
  const cnvsWidth = noMargin ? noMarginConf.cnvsWidth : conf.cnvsWidth
  const cnvsHeight = noMargin ? noMarginConf.cnvsHeight : conf.cnvsHeight
  const width = conf.width
  const height = conf.height
  const arrow = conf.arrow
  const gap = conf.gap
  const widthIncrement = conf.widthIncrement
  const lineX = x + conf.offset
  const currentLetterHeight = 2 * height
  const currentLetterArrow = 2 * arrow
  const graphHeight = (lettersArray.length - 1) * (height + gap) + currentLetterHeight
  const currentLetterTxtStyle = { txt: consumptionClass ?? '', style: conf.currentLetter.style, font: fonts.currentLetter }
  const consumptionX = x + conf.sepparator.offset / 2
  const emissionX = lineX - (conf.offset - conf.sepparator.offset) / 2
  const textConsommationScore = textFromModel(textModels.consommationScore, consumption ?? '')
  const textEmissionsScore = textFromModel(textModels.emissionsScore, emissions ?? '')

  let afterCurrentLetterOffsetH = 0
  let afterCurrentLetterOffsetW = 0

  const d = new CanvasDrawer(cnvs)

  d.autosize(cnvsWidth, cnvsHeight, fixedWidth, fixedHeight)

  if (isNotConcerned) d.ctx.globalAlpha = 0.2;

  d.drawText(lineX, y - conf.perfTextGap, texts('goodPerf'));
  d.drawText(lineX, y + graphHeight + conf.perfTextGap, texts('badPerf'), null, null, 'top');

  if (consumptionClass != 'F' && consumptionClass != 'G') {
    d.drawLine([lineX - conf.passoireMark.gap, y + graphHeight - height * 2 - gap], [lineX - conf.passoireMark.gap, y + graphHeight], conf.greyLine);
    d.drawText(lineX - (conf.passoireMark.gap + conf.passoireMark.textGap), y + graphHeight - height - gap, texts('passoireEnergetique1'), null, 'right');
    d.drawText(lineX - (conf.passoireMark.gap + conf.passoireMark.textGap), y + graphHeight - height, texts('passoireEnergetique2'), null, 'right', 'top');
  }

  for (let i = 0; lettersArray.length > i; i++) {
    const lineY = y + i * (height + gap) + afterCurrentLetterOffsetH,
      lineWidth = width + widthIncrement * i + afterCurrentLetterOffsetW;
    if (consumptionClass == lettersArray[i][1].letter) {
      const scoresY = lineY + currentLetterHeight * conf.scoreBaselinePercentFromTop / 100;
      d.drawLetterRow(lineX, lineY, lineWidth, currentLetterHeight, currentLetterArrow, lettersArray[i][1].color);
      d.drawLine([lineX, lineY], [lineX, lineY + currentLetterHeight], conf.centerSepparatorStyle);
      d.drawLine([x + conf.sepparator.offset, lineY + conf.strokeWidth * 3],
        [x + conf.sepparator.offset, lineY + currentLetterHeight - conf.sepparator.style.strokeWidth * 3],
        conf.sepparator.style);
      d.drawRoundedLetterRow(x, lineY, lineWidth + conf.offset, currentLetterHeight, currentLetterArrow, conf.currentLetter.rowStyle, conf.roundWidth);
      d.drawText(lineX + conf.currentLetter.letterGap.x, lineY + currentLetterHeight - conf.currentLetter.letterGap.y, currentLetterTxtStyle, true);
      d.drawText(consumptionX, lineY - conf.currentLetter.labelGap, texts('primaryEnergy'), null, "center", "bottom");

      d.drawText(consumptionX, lineY - texts('primaryEnergy').font.lineHeight, texts('consommation'), null, "center", "bottom");
      d.drawText(consumptionX, scoresY, textConsommationScore, null, "center");
      d.drawText(consumptionX, scoresY + conf.currentLetter.unitGap, texts('consommationUnit'), null, "center", "top");

      if (finalConsumption && typeof finalConsumption !== 'undefined') {
        const finalEnergyTetxStyle = textFromModel(
          texts('finalEnergyUnit'),
          finalConsumption + ' ' + texts('finalEnergyUnit').txt
        )

        d.drawText(consumptionX, lineY + currentLetterHeight + conf.currentLetter.labelGap, finalEnergyTetxStyle, null, "center", "top");
        d.drawText(consumptionX, lineY + currentLetterHeight + conf.currentLetter.labelGap + finalEnergyTetxStyle.font.lineHeight, texts('ofFinalEnergy'), null, "center", "top");
      }

      d.drawText(emissionX, lineY - conf.currentLetter.labelGap, texts('emissions'), null, "center", "bottom");
      const asteriskMetics = d.getTextMetrix(conf.emissionAsterisk)
      const gesTxtMetics = d.drawText(emissionX - asteriskMetics.width / 2, scoresY, textEmissionsScore, null, "center", null, true);
      if (!isNotConcerned && gesTxtMetics) d.drawText(emissionX - asteriskMetics.width / 2 + gesTxtMetics.width / 2, scoresY - gesTxtMetics.actualBoundingBoxAscent, conf.emissionAsterisk, null, null, "top");
      d.drawText(emissionX, scoresY + conf.currentLetter.unitGap, texts('emissionsUnit'), null, "center", "top");

      if (consumptionClass == 'F' || consumptionClass == 'G') {
        d.drawRounded90Angle(lineX + lineWidth + currentLetterArrow, lineY + height, conf.passoireIcon[consumptionClass].offsetX, conf.passoireIcon[consumptionClass].offsetY, 'top', conf.passoireIcon.style, conf.passoireIcon.lineRadius);
        d.drawCircle(lineX + lineWidth + currentLetterArrow + conf.passoireIcon[consumptionClass].offsetX, lineY + height - conf.passoireIcon[consumptionClass].offsetY - conf.passoireIcon.circleRadius, conf.passoireIcon.circleRadius, conf.passoireIcon.style);

        if (strainerImage) {
          d.ctx.drawImage(/** @type {HTMLImageElement} */(strainerImage),
            lineX + lineWidth + currentLetterArrow + conf.passoireIcon[consumptionClass].offsetX - conf.passoireIcon.imgWidth / 2,
            lineY + height - conf.passoireIcon[consumptionClass].offsetY - conf.passoireIcon.circleRadius - conf.passoireIcon.imgWidth / 2,
            conf.passoireIcon.imgWidth,
            conf.passoireIcon.imgWidth);
        }
      }
      afterCurrentLetterOffsetH = currentLetterHeight - height;
      afterCurrentLetterOffsetW = widthIncrement;
    } else {
      d.drawLetterRow(lineX, lineY, lineWidth, height, arrow, lettersArray[i][1].color);
      d.drawText(lineX + conf.lettersGap.x, lineY + height - conf.lettersGap.y, { txt: lettersArray[i][1].letter, style: styles.letters, font: fonts.letters });
    }
  }

  if (isNotConcerned) {
    d.ctx.globalAlpha = 1;
    d.drawText(cnvsWidth / 2, (cnvsHeight / 2) - (conf.notConcernedGap / 2), texts('notConcerned'), false, 'center', 'bottom');
    d.drawText(cnvsWidth / 2, (cnvsHeight / 2) + (conf.notConcernedGap / 2), texts('notConcerned2'), false, 'center', 'top');
  }
}

/**
 * @param {{
*  cnvs: HTMLCanvasElement | import('canvas').Canvas;
*  fixedWidth?: number | null;
*  fixedHeight?: number | null;
*  noMargin?: boolean | null;
* version?: Version | (string & {}) | null;
* lang?: Lang | null;
* }} config
*/
export function drawNotConcernedFrenchDiagnosticLabel({ cnvs, fixedWidth, fixedHeight, noMargin, version, lang }) {
  return drawFrenchDiagnosticLabel({ cnvs, fixedWidth, fixedHeight, isNotConcerned: true, noMargin, version, lang });
}

/**
 * @param {Translations} translations
 * @param {TextModels} textModels
 * 
 * @returns {(key: TranslationKey) => import("./CanvasDrawer.js").Text}
 */
function makeTranslationsGetter(translations, textModels) {
  return (key) => textFromModel(textModels[key], translations[key])
}