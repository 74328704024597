import { CanvasDrawer, Font, textFromModel } from "./CanvasDrawer.js"
import { recursiveMerge } from "./helpers.js"

/**
 * @typedef { 'title1' | 'title2' | 'goodEmissions' | 'badEmissions1' | 'badEmissions2' | 'emissionsUnit' } TranslationKey
*/

/**
 * @typedef { TranslationKey | 'emissionsScore' } TranslationModelKey
*/

/**
 * @typedef {Record<TranslationKey, string>} Translations
*/

/**
 * @template [T=import('./CanvasDrawer.js').TextModel]
 * @typedef {Record<TranslationModelKey, T>} TextModels
 */

/**
 * @typedef {'white'} Version
 */

let colors = {
    dark: '#1c1c1b',
    grey: '#9d9c9c',
    letters: '#fff',
    white: '#fff',
}

let fonts = {
    title: new Font('IBMPlexSans-bold', 9, '700'),
    small: new Font('IBMPlexSans', 7, '500'),
    smallCondensed: new Font('IBMPlexSansCondensed', 7, '500'),
    letters: new Font('IBMPlexSans', 9, '500'),
    currentLetter: new Font('IBMPlexSans-bold', 19, '700'),
    scores: new Font('IBMPlexSansCondensed-bold', 13, '700'),
    unit: new Font('IBMPlexSansCondensed', 5, '500'),
}

let styles = {
    dark: { fill: colors.dark },
    grey: { fill: colors.grey },
    letters: { fill: colors.letters },
    white: { fill: colors.white },
}

let stroke = { fill: null, stroke: '#1d1d1b', strokeWidth: 1 }

let baseConf = {
    cnvsWidth: 189.18,
    cnvsHeight: 258.66,
    x: 14.721,
    y: 24.143,
    width: 17.932,
    height: 10.556,
    gap: 1.482,
    widthIncrement: 8.372,
    scoreLine: stroke,
    currentLetter: {
        style: { fill: colors.letters, stroke: stroke.stroke, strokeWidth: stroke.strokeWidth },
        rowStyle: { fill: null, stroke: stroke.stroke, strokeWidth: stroke.strokeWidth },
        widthIncrementPercent: 76.2
    },
    offsetX: 10.622,
    offsetY: 48.941,
    contour: {
        color: '#a3daf7',
        width: 156.022,
        height: 169.996,
        strokeWidth: 1.5,
        radius: 5,
    },
    perfTextGap: 3,
}

let versions = {
    white: {
        scoreLine: { stroke: '#fff' },
        currentLetter: { rowStyle: { stroke: '#fff' } }
    }
}

let noMarginConf = {
    cnvsWidth: 160,
    cnvsHeight: 174,
    x: 2,
    y: 2
}

/** @type {Record<import("./helpers.js").Lang, Translations>} */
const baseTranslations = {
    fr: {
        title1: '* Dont émissions de gaz',
        title2: 'à effet de serre',
        goodEmissions: 'peu d’émissions de CO₂',
        badEmissions1: 'émissions de CO₂',
        badEmissions2: 'très importantes',
        emissionsUnit: 'kg CO₂/m²/an',
    },
    en: {
        "title1": "* Including greenhouse",
        "title2": "gas emissions",
        "goodEmissions": "low CO₂ emissions",
        "badEmissions1": "very high",
        "badEmissions2": "CO₂ emissions",
        "emissionsUnit": "kg CO₂/m²/year"
    },
    es: {
        "title1": "* Incluidas las emisiones de",
        "title2": "gases de efecto invernadero",
        "goodEmissions": "bajas emisiones de CO₂",
        "badEmissions1": "emisiones de CO₂",
        "badEmissions2": "muy altas",
        "emissionsUnit": "kg CO₂/m²/año"
    },
    it: {
        "title1": "* Comprese le emissioni",
        "title2": "di gas a effetto serra",
        "goodEmissions": "basse emissioni di CO₂",
        "badEmissions1": "emissioni di CO₂",
        "badEmissions2": "molto elevate",
        "emissionsUnit": "kg CO₂/m²/anno"
    },
    pt: {
        "title1": "* Incluindo as emissões de",
        "title2": "gases com efeito de estufa",
        "goodEmissions": "baixas emissões de CO₂",
        "badEmissions1": "emissões de CO₂",
        "badEmissions2": "muito altas",
        "emissionsUnit": "kg CO₂/m²/ano"
    },
    de: {
        "title1": "* Darunter",
        "title2": "Treibhausgasemissionen",
        "goodEmissions": "geringe CO₂-Emissionen",
        "badEmissions1": "sehr hoch",
        "badEmissions2": "CO₂-Emissionen",
        "emissionsUnit": "kg CO₂/m²/Jahr"
    },
}

/** @type {TextModels} */
const baseTextModels = {
    title1: { style: styles.dark, font: fonts.title },
    title2: { style: styles.dark, font: fonts.title },
    goodEmissions: { style: { fill: '#8bb3d3' }, font: fonts.small },
    badEmissions1: { style: { fill: '#271b35' }, font: fonts.small },
    badEmissions2: { style: { fill: '#271b35' }, font: fonts.small },
    emissionsScore: { style: styles.dark, font: fonts.scores },
    emissionsUnit: { style: styles.dark, font: fonts.unit },
}

/** @type {Record<Version, Partial<TextModels<Partial<import('./CanvasDrawer.js').TextModel>>>>} */
let textModelsVersions = {
    white: {
        title1: { style: styles.white },
        title2: { style: styles.white },
        badEmissions1: { style: styles.white },
        badEmissions2: { style: styles.white },
        emissionsScore: { style: styles.white },
        emissionsUnit: { style: styles.white }
    }
}

let gesLetters = {
    A: { letter: 'A', color: '#a3daf8', scoreLineLength: 21.26 },
    B: { letter: 'B', color: '#8cb4d3', scoreLineLength: 21.26 },
    C: { letter: 'C', color: '#7792b1', scoreLineLength: 21.26 },
    D: { letter: 'D', color: '#606f8f', scoreLineLength: 21.26 },
    E: { letter: 'E', color: '#4d5271', scoreLineLength: 21.26 },
    F: { letter: 'F', color: '#393551', scoreLineLength: 15.52 },
    G: { letter: 'G', color: '#281b35', scoreLineLength: 6.11 }
}

/**
 * @param {{
*  cnvs: HTMLCanvasElement | import('canvas').Canvas;
*  fixedWidth?: number | null;
*  fixedHeight?: number | null;
*  emissionsClass?: string | null;
*  emissions?: number | string | null;
*  isNotConcerned?: boolean | null;
*  noMargin?: boolean | null;
*  version?: string | null;
*  lang?: import("./helpers.js").Lang | null;
* }} config
*/
export function drawFrenchGesLabel({ cnvs, emissionsClass, emissions, fixedWidth, fixedHeight, isNotConcerned, noMargin, version, lang }) {
    if (!lang) lang = 'fr'

    const conf =
        typeof version === 'string' && typeof versions[version] === 'object'
            ? recursiveMerge(baseConf, versions[version])
            : baseConf

    const textModels =
    version && typeof textModelsVersions[version] === 'object'
        ? recursiveMerge(baseTextModels, textModelsVersions[version])
        : baseTextModels

    const texts = makeTranslationsGetter(baseTranslations[lang] ?? baseTranslations['fr'], textModels)

    if (isNotConcerned) emissionsClass = 'D';
    const lettersArray = Object.entries(gesLetters),
        x = noMargin ? noMarginConf.x : conf.x,
        y = noMargin ? noMarginConf.y : conf.y,
        cnvsWidth = noMargin ? noMarginConf.cnvsWidth : conf.cnvsWidth,
        cnvsHeight = noMargin ? noMarginConf.cnvsHeight : conf.cnvsHeight,
        width = conf.width,
        height = conf.height,
        gap = conf.gap,
        offsetX = conf.offsetX,
        offsetY = conf.offsetY,
        widthIncrement = conf.widthIncrement,
        currentLetterHeight = 2 * height,
        graphHeight = (lettersArray.length - 1) * (height + gap) + currentLetterHeight,
        lineX = x + offsetX,
        textEmissionsScore = textFromModel(textModels.emissionsScore, emissions ?? '');

    let afterCurrentLetterOffsetH = 0, afterCurrentLetterOffsetW = 0;

    const d = new CanvasDrawer(cnvs)

    d.autosize(cnvsWidth, cnvsHeight, fixedWidth, fixedHeight);

    if (isNotConcerned) d.ctx.globalAlpha = 0.2;

    d.drawRoundedRectangle(x, y, conf.contour.width, conf.contour.height, conf.contour.radius, {
        fill: null,
        stroke: conf.contour.color,
        strokeWidth: conf.contour.strokeWidth,
    })

    d.drawText(lineX, y + offsetX, texts('title1'), null, null, 'top');
    d.drawText(lineX, y + offsetX + texts('title1').font.lineHeight, texts('title2'), null, null, 'top');

    d.drawText(lineX, y + offsetY - conf.perfTextGap, texts('goodEmissions'));
    d.drawText(lineX, y + offsetY + graphHeight + conf.perfTextGap, texts('badEmissions1'), null, null, 'top');
    d.drawText(lineX, y + offsetY + graphHeight + conf.perfTextGap + texts('badEmissions1').font.lineHeight, texts('badEmissions2'), null, null, 'top');

    for (let i = 0; lettersArray.length > i; i++) {
        const lineY = y + offsetY + i * (height + gap) + afterCurrentLetterOffsetH,
            lineWidth = width + widthIncrement * i + afterCurrentLetterOffsetW;
        if (emissionsClass == lettersArray[i][1].letter) {
            const currentLetterLineWidth = lineWidth - ((100 - conf.currentLetter.widthIncrementPercent) / 100) * height,
                currentLetterRowEndX = lineX + currentLetterLineWidth + currentLetterHeight / 2,
                currentLetterRowMidY = lineY + currentLetterHeight / 2,
                scoreLineGap = conf.currentLetter.style.strokeWidth * 2;
            d.drawGesLetterRow(lineX, lineY, currentLetterLineWidth, currentLetterHeight, { fill: lettersArray[i][1].color, stroke: conf.currentLetter.rowStyle.stroke, strokeWidth: conf.currentLetter.rowStyle.strokeWidth }, true);
            d.drawText(lineX + 3, lineY + currentLetterHeight - 4, { txt: lettersArray[i][1].letter, style: conf.currentLetter.style, font: fonts.currentLetter }, true);
            if (!isNotConcerned) {
                d.drawLine(
                    [currentLetterRowEndX + scoreLineGap, currentLetterRowMidY],
                    [currentLetterRowEndX + lettersArray[i][1].scoreLineLength + scoreLineGap, currentLetterRowMidY],
                    conf.scoreLine);
                const scoreTxtMetics = d.drawText(
                    currentLetterRowEndX + lettersArray[i][1].scoreLineLength + scoreLineGap * 2,
                    currentLetterRowMidY, textEmissionsScore, null, null, 'middle', true);

                if (scoreTxtMetics) {
                    d.drawText(
                        currentLetterRowEndX + lettersArray[i][1].scoreLineLength + scoreLineGap * 2 + scoreTxtMetics.width + 1,
                        currentLetterRowMidY + scoreTxtMetics.actualBoundingBoxDescent, texts('emissionsUnit'));
                }
            }
            afterCurrentLetterOffsetH = currentLetterHeight - height;
            afterCurrentLetterOffsetW = widthIncrement;
        } else {
            d.drawGesLetterRow(lineX, lineY, lineWidth, height, { fill: lettersArray[i][1].color });
            d.drawText(lineX + 2, lineY + height - 2.136, { txt: lettersArray[i][1].letter, style: styles.letters, font: fonts.letters });
        }
    }
}
/**
 * @param {{
*  cnvs: HTMLCanvasElement | import('canvas').Canvas;
*  fixedWidth?: number | null;
*  fixedHeight?: number | null;
*  emissionsClass?: string | null;
*  emissions?: number | string | null;
*  isNotConcerned?: boolean | null;
*  noMargin?: boolean | null;
*  version?: string | null;
*  lang?: import("./helpers.js").Lang | null;
* }} config
*/
export function drawNotConcernedFrenchGesLabel({ cnvs, fixedWidth, fixedHeight, noMargin, version, lang }) {
    return drawFrenchGesLabel({ cnvs, fixedWidth, fixedHeight, noMargin, version, isNotConcerned: true, lang });
}

/**
 * @param {Translations} translations
 * @param {TextModels} textModels
 * 
 * @returns {(key: TranslationKey) => import("./CanvasDrawer.js").Text}
 */
function makeTranslationsGetter(translations, textModels) {
    return (key) => textFromModel(textModels[key], translations[key])
  }